import { useEffect, useState, useContext, useRef } from "react";
import { Link as ScrollLink } from "react-scroll";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";
import GoogleConnect from "../components/GoogleConnect.js";

import useModal from "./useModal";
import useScrollPosition from "./useScrollPosition";
import RegisterButton from "./RegisterButton.js";

import { Switch, Modal, Button, Form, Input, Tooltip, Alert } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { hotjar } from "react-hotjar";

import mixpanel from "mixpanel-browser";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Plans from "../components/Plans.js";
import PlansApi from "../components/PlansApi.js";
import PlansSettings from "./PlansSettings.js";
import Loader from "./Loader.js";
import axios from "axios";

// import Tuto from './Tuto.js';

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n";

import "./navBar.css";
import "./AdminSideBar.css";

const NavBar = (props) => {
  hotjar.initialize(3700912, 6);

  const ref3 = useRef(null);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  const { teamDetails, setTeamId } = useContext(AuthContext);
  const context = useContext(AuthContext);

  const { isFreeTrialOver, daysRemainingForTrial } = useContext(AuthContext);

  const teamId = context.teamId;
  const socialInboxActivate = teamDetails?.socialInbox;

  const MAX_PRO_MEMBERS = 3;
  const MAX_ULTIMATE_MEMBERS = 5;

  const MAX_KEYWORDS_PRO = 3;
  const MAX_KEYWORDS_ULTIMATE = 10;

  const [user, setUser] = useState();
  const [showModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [openNavClass, setOpenNavClass] = useState("");
  const [newKeyword, setNewKeyword] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isKeywordModalOpen, setIsKeywordModalOpen] = useState(false);
  const [activePage, setActivePage] = useState("General");
  const [base64Image, setBase64Image] = useState(null);
  const [showKeywordMenu, setShowKeywordMenu] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [keywordDescription, setKeywordDescription] = useState("");
  const [keywordIntent, setkeywordIntent] = useState("");
  const [keywordURL, setKeywordURL] = useState("");
  const [exclusionUrls, setExclusionUrls] = useState([]);
  const [isPremiumTeam, setIsPremiumTeam] = useState(false);
  const [premiumTeamCondition, setPremiumTeamCondition] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);
  const [showPlansApi, setShowPlansApi] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [keywordToDelete, setKeywordToDelete] = useState(null);
  const roles = ["admin", "viewer"];
  const [isEmailActivate, setIsEmailActivate] = useState(true);
  const [browserNotifications, setBrowserNotifications] = useState({});
  const [emailNotifications, setEmailNotifications] = useState({});
  const [teamName, setTeamName] = useState(
    teamDetails && teamDetails.name ? teamDetails.name : ""
  );
  const [currency, setCurrency] = useState(
    teamDetails && teamDetails.currency ? teamDetails.currency : ""
  );
  const [firstName, setFirstName] = useState(
    context && context.userInfos ? context.userInfos.firstname : ""
  );
  const [lastName, setLastName] = useState(
    context && context.userInfos ? context.userInfos.surname : ""
  );
  const [email, setEmail] = useState(
    context && context.userInfos ? context.userInfos.email : ""
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalKeywordConfirmation, setIsModalKeywordConfirmation] =
    useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [modalOpenDiscord, setModalOpenDiscord] = useState(false);
  const [modalOpenSlack, setModalOpenSlack] = useState(false);
  const [modalOpenWebhook, setModalOpenWebhook] = useState(false);
  const [discordUrl, setDiscordUrl] = useState("");
  const [slackUrl, setSlackUrl] = useState("");
  const [webhookUrl, setWebhookUrl] = useState("");
  const [isDiscordConnected, setIsDiscordConnected] = useState(false);
  const [isSlackConnected, setIsSlackConnected] = useState(false);
  const [isWebhookConnected, setIsWebhookConnected] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  const [isOpenLang, setIsOpenLang] = useState();
  const [showRegenerateModal, setShowRegenerateModal] = useState(false);
  const [newApiKey, setNewApiKey] = useState("");
  const [oldKeyword, setOldKeyword] = useState("");
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [numberCreditApi, setNumberCreditApi] = useState(0);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [isLinkedInCookieSet, setIsLinkedInCookieSet] = useState(false);
  const [showLinkedInMessage, setShowLinkedInMessage] = useState(false);
  const [linkedinCookieInvalid, setLinkedinCookieInvalid] = useState(false);

  const platforms = [
    { name: "LinkedIn", icon: "fab fa-linkedin", isPremium: false },
    { name: "YouTube", icon: "fab fa-youtube", isPremium: false },
    { name: "Twitter", icon: "fab fa-twitter", isPremium: false },
    { name: "Instagram", icon: "fab fa-instagram", isPremium: false },
    {
      name: "News",
      customIcon: "/social/news.png",
      isPremium: false,
    },
    { name: "Internet", icon: "fab fa-google", isPremium: false },
    { name: "Reddit", icon: "fab fa-reddit", isPremium: false },
    { name: "Pinterest", icon: "fab fa-pinterest", isPremium: false },
    { name: "Medium", icon: "fab fa-medium", isPremium: false },
    { name: "Quora", icon: "fab fa-quora", isPremium: false },
    {
      name: "IndieHacker",
      customIcon: "/social/indiehacker.png",
      isPremium: false,
    },
    {
      name: "Everywhere",
      icon: "fab fa-google",
      isPremium: true,
    },
    { name: "Tiktok", icon: "fab fa-tiktok", isPremium: true },
    { name: "Facebook", icon: "fab fa-facebook", isPremium: true },
  ];

  const keywordMenuRef = useRef(null);
  const userMenuRef = useRef(null);

  // const [currentKeywordCount, setCurrentKeywordCount] = useState(
  //   context.teamDetails.keyword.length || 0
  // );

  const currentKeywordCount =
    teamDetails && teamDetails.keyword ? teamDetails.keyword.length : 0;

  const currentTeamMembersCount =
    teamDetails && teamDetails.members ? teamDetails.members.length : 0;

  let currentPlan = "Free";
  if (teamDetails && teamDetails.plan && teamDetails.plan.length > 0) {
    currentPlan = teamDetails.plan[0].productName;
  }

  useEffect(() => {
    if (
      context?.userInfos?.onboarding === true &&
      location.pathname !== "/dashboard"
    ) {
      navigate("/dashboard");
    }
  }, [context, navigate, location.pathname]);

  const handleInputChange = (e) => {
    setNewKeyword(e.target.value);
  };

  const toggleKeywordMenu = () => {
    setShowKeywordMenu(!showKeywordMenu);
  };

  function handleKeywordChange(key) {
    context.setKeyword(key);
    localStorage.setItem("selectedKeyword", key);
    setShowKeywordMenu(false);
  }

  const editKeyword = (keywordObj) => {
    setIsEditing(true);
    setIsKeywordModalOpen(true);
    setOldKeyword(keywordObj.keywordName);
    setCurrentKeyword(keywordObj.keywordName);
    setKeywordDescription(keywordObj.description);
    setkeywordIntent(keywordObj.intent || "");
    setKeywordURL(keywordObj.website);
    setExclusionUrls(keywordObj.urls || []);
  };

  const handleDarkModeToggle = (checked) => {
    // setIsDarkMode(checked);
    if (checked) {
      console.log("dark mode activé");
    } else {
      console.log("dark mode désactivé");
    }
  };

  const onFinish = (values) => {
    AddKeywordNew(values);
  };

  const handleLinkClick = (e) => {
    e.preventDefault();
    setShowPlansApi(true);
  };

  const handleClosePlansApi = () => {
    setShowPlansApi(false);
  };

  useEffect(() => {
    if (teamDetails) {
      setTeamName(teamDetails.name);
    }
  }, [teamDetails]);

  useEffect(() => {
    const fetchSelectedPlatforms = async () => {
      if (context.teamId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/selected-platforms`,
            {
              headers: {
                Authorization: `Bearer ${context.token}`,
              },
            }
          );
          const data = await response.json();
          if (data.success) {
            setSelectedPlatforms(data.selectedPlatforms);
          }
        } catch (error) {
          console.error("Failed to fetch selected platforms:", error);
        }
      }
    };

    const checkLinkedInCookie = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/api/user/linkedin-cookie`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                context.token || localStorage.getItem("Token")
              }`,
            },
          }
        );

        const responseData = await response.json();
        setIsLinkedInCookieSet(
          response.ok && responseData.linkedinCookiePresent
        );
      } catch (error) {
        console.error("Error checking LinkedIn cookie:", error);
        setIsLinkedInCookieSet(false);
      }
    };

    fetchSelectedPlatforms();
    checkLinkedInCookie();
  }, [context.teamId, context.token]);

  const handlePlatformToggle = (platform) => {
    if (platform.isPremium && currentPlan !== "Ultimate") {
      toast.error(t("Upgrade_to_ultimate_to_select_premium_platforms"));
      return;
    }

    // Vérifie si la plateforme est déjà sélectionnée
    const isSelected = selectedPlatforms.includes(platform.name);

    // Met à jour les plateformes sélectionnées
    setSelectedPlatforms((prev) =>
      isSelected
        ? prev.filter((item) => item !== platform.name)
        : [...prev, platform.name]
    );

    // Affiche ou masque le message LinkedIn en fonction de la sélection
    if (platform.name === "LinkedIn") {
      setShowLinkedInMessage(!isSelected);
    }
  };

  const handleSavePlatforms = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/teams/update-selected-platforms`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({
            teamId: context.teamId,
            platforms: selectedPlatforms,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        console.log("Selected platforms updated successfully");
      } else {
        console.error("Failed to update selected platforms:", data.msg);
      }
    } catch (error) {
      console.error(
        "There was an error updating the selected platforms:",
        error
      );
    }
  };

  const loadInvitations = async () => {
    if (!context.token) {
      return;
    }

    const teamId = context.teamId;
    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${teamId}/invitations`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
      });
      const data = await response.json();
      if (data.success) {
        setInvitations(data.invitations);
      } else {
        // Gérer les erreurs ici
        console.error("Failed to load invitations:", data.message);
      }
    } catch (error) {
      console.error("Error fetching invitations:", error);
    }
  };

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const teamId = context.teamId;
        const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${teamId}/credits`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
        });
        const data = await response.json();
        setNumberCreditApi(data.credits);
      } catch (error) {
        console.error("Error fetching API credits", error);
      }
    };

    fetchCredits();
  }, [context.token, context.teamId]);

  const dataempty = [
    { month: "Jan", requests: 0 },
    { month: "Feb", requests: 0 },
    { month: "Mar", requests: 0 },
    { month: "Apr", requests: 0 },
    { month: "May", requests: 0 },
    { month: "Jun", requests: 0 },
    { month: "Jul", requests: 0 },
    { month: "Aug", requests: 0 },
    { month: "Sep", requests: 0 },
    { month: "Oct", requests: 0 },
    { month: "Nov", requests: 0 },
    { month: "Dec", requests: 0 },
  ];

  const [data, setData] = useState([]);

  const handleClickOpenPlan = () => {
    setShowPlansModal(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const teamId = context.teamId;
        const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${teamId}/api-calls`;
        const response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
        });
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error("Error fetching API call data", error);
      }
    };

    fetchData();
  }, []);

  const APIGraph = () => {
    return (
      <div className="leftPosition">
        {data.length === 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={dataempty}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="requests"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={data}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="requests"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  };

  // Utilisez useEffect pour charger les invitations au montage du composant
  useEffect(() => {
    loadInvitations();
  }, []);

  useEffect(() => {
    if (context && context.userInfos) {
      setFirstName(context.userInfos.firstname);
      setLastName(context.userInfos.surname);
      setEmail(context.userInfos.email);
    }
  }, [context]);

  const disconnectUser = () => {
    context.disconnect();
  };

  const openEditModal = (keyword) => {
    setCurrentKeyword(keyword);
    setIsEditing(true);
    setIsKeywordModalOpen(true);
  };

  const closeModal = () => {
    setIsKeywordModalOpen(false);
    setCurrentKeyword("");
    setKeywordDescription("");
    setkeywordIntent("");
    setKeywordURL("");
    setExclusionUrls([]);
    setIsEditing(false);
  };

  const toggleModalLang = () => {
    setIsOpenLang(false);
    localStorage.setItem("isDefinedLang", true);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("isDefinedLang", true);
  };

  useEffect(() => {
    // if token doesnt exist, open modal
    if (!localStorage.getItem("isDefinedLang")) {
      setIsOpenLang(true);
    }
    // if token exist, close modal
    else {
      setIsOpenLang(false);
    }
  }, []);

  const disconnectUserNavBar = () => {
    context.disconnect();
    handleNavClick();
  };

  const toggleNav = () => {
    setIsOpenNav(!isOpenNav);
  };

  const toggleModalLogin = () => {
    setIsOpenLogin((prevState) => !prevState);
  };

  const hideForGGC = () => {
    hide();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeModalSettings = () => {
    setIsSettingsModalOpen(false);
  };

  const toggleSettingsModal = () => {
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  const closeModalKeyword = () => {
    setIsKeywordModalOpen(false);
  };

  const toggleKeywordModal = () => {
    if (canAddKeyword()) {
      setIsKeywordModalOpen(!isSettingsModalOpen);
    } else {
      // Informez l'utilisateur qu'il a atteint la limite pour son plan actuel
      toast.error(t("youve_reached_the_keyword_limit"));
    }
  };

  const isValidEmail = (email) => {
    try {
      const regex = /^[a-zA-Z0-9._+\-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      return regex.test(email);
    } catch (error) {
      console.error("Erreur lors de la validation de l'email: ", error);
      return false;
    }
  };

  const handleOpenModalDiscordDisconnect = async () => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/disconnect-discord`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
      });

      if (response.ok) {
        // Mise à jour de l'état pour refléter la déconnexion
        setIsDiscordConnected(false);
        setDiscordUrl(""); // Réinitialiser l'URL si nécessaire

        mixpanel.track("Notification_Discord", {
          user: context.userInfos.email,
          status: "Desactivate",
        });
      } else {
        // Gérer les erreurs, par exemple, afficher un message d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de la déconnexion de Discord: ", error);
      // Gérer l'erreur
    }
  };

  const handleOpenModalSlackDisconnect = async () => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/disconnect-slack`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
      });

      if (response.ok) {
        // Mise à jour de l'état pour refléter la déconnexion
        setIsSlackConnected(false);

        mixpanel.track("Notification_Slack", {
          user: context.userInfos.email,
          status: "Desactivate",
        });

        setSlackUrl(""); // Réinitialiser l'URL si nécessaire
      } else {
        // Gérer les erreurs, par exemple, afficher un message d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de la déconnexion de Slack: ", error);
      // Gérer l'erreur
    }
  };

  const handleDisconnectWebhook = async () => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/disconnect-webhook`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
      });

      if (response.ok) {
        // Mise à jour de l'état pour refléter la déconnexion
        setIsWebhookConnected(false);
        setWebhookUrl(""); // Réinitialiser l'URL si nécessaire

        mixpanel.track("Notification_Webhook", {
          user: context.userInfos.email,
          status: "Desactivate",
        });
      } else {
        // Gérer les erreurs, par exemple, afficher un message d'erreur
      }
    } catch (error) {
      console.error("Erreur lors de la déconnexion de Webhook: ", error);
      // Gérer l'erreur
    }
  };

  const handleChangeCurrency = async (newCurrency) => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/update-currency`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
        body: JSON.stringify({
          currency: newCurrency,
        }),
      });

      if (response.ok) {
        context.setTeamDetails((prevDetails) => ({
          ...prevDetails,
          currency: newCurrency,
        }));
      } else {
        console.error("Erreur lors de la mise à jour de la devise");
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la devise: ", error);
    }
  };

  const testWebhookConnection = (webhookUrl) => {
    const fakeData = {
      keyword: "Buska.io",
      totalMentions: 2,
      mentions: [
        {
          content: "Follow us on our LinkedIn page",
          link: "https://www.linkedin.com/company/buskapp",
        },
        {
          content: "Follow us on our Twitter page",
          link: "https://twitter.com/Buska_io",
        },
      ],
    };

    axios
      .post(webhookUrl, fakeData)
      .then((response) => {
        toast.success(t("data_send_to_webhook"));
      })
      .catch((error) => {
        toast.error(t("error") + error.message);
        console.log("error" + error.message);
        // alert("Erreur lors du test de connexion : " + error.message);
      });
  };

  const handleCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    setCurrency(newCurrency);
    handleChangeCurrency(newCurrency);
  };

  const handleOpenModalDiscord = () => {
    setModalOpenDiscord(true);
  };

  const handleCloseModalDiscord = () => {
    setModalOpenDiscord(false);
  };

  const handleOpenModalSlack = () => {
    setModalOpenSlack(true);
  };

  const handleCloseModalSlack = () => {
    setModalOpenSlack(false);
  };

  const handleOpenModalWebhook = () => {
    setModalOpenWebhook(true);
  };

  const handleCloseModalWebhook = () => {
    setModalOpenWebhook(false);
  };

  const handleSubmitUrlNotifDiscord = async () => {
    const source = "discord";
    const apiUrl =
      process.env.REACT_APP_BASEURL +
      `/api/teams/add-discord-notif/` +
      context.teamId;

    // Vérifier si l'URL de Discord commence par le préfixe attendu
    const discordWebhookPrefix = "https://discord.com/api/webhooks/";
    if (!discordUrl.startsWith(discordWebhookPrefix)) {
      toast.error(t("error"));
      return;
    }
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      },
      body: JSON.stringify({
        notifications: {
          type: source,
          value: discordUrl,
        },
      }),
    });

    if (response.ok) {
      // Gérer la réussite de la requête
      await checkDiscordConnection();
      setUpdateTrigger((prev) => prev + 1); // Change la valeur pour déclencher useEffect
      handleCloseModalDiscord();

      mixpanel.track("Notification_Discord", {
        user: context.userInfos.email,
        status: "Activate",
      });
    } else {
      // Gérer l'erreur
    }
  };

  useEffect(() => {
    checkDiscordConnection();
  }, [updateTrigger]);

  const handleSubmitUrlNotifSlack = async () => {
    const source = "slack";
    const apiUrl =
      process.env.REACT_APP_BASEURL +
      `/api/teams/add-slack-notif/` +
      context.teamId;

    // Vérifier si l'URL de Discord commence par le préfixe attendu
    const slackWebhookPrefix = "https://hooks.slack.com";
    if (!slackUrl.startsWith(slackWebhookPrefix)) {
      toast.error(t("error"));
      return;
    }
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      },
      body: JSON.stringify({
        notifications: {
          type: source,
          value: slackUrl,
        },
      }),
    });

    if (response.ok) {
      // Gérer la réussite de la requête
      await checkSlackConnection();
      setUpdateTrigger((prev) => prev + 1); // Change la valeur pour déclencher useEffect
      handleCloseModalSlack();

      mixpanel.track("Notification_Slack", {
        user: context.userInfos.email,
        status: "Activate",
      });
    } else {
      // Gérer l'erreur
    }
  };

  useEffect(() => {
    checkSlackConnection();
  }, [updateTrigger]);

  const handleSubmitUrlNotifWebhook = async () => {
    const source = "webhook";
    const apiUrl =
      process.env.REACT_APP_BASEURL +
      `/api/teams/add-webhook-notif/` +
      context.teamId;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      },
      body: JSON.stringify({
        notifications: {
          type: source,
          value: webhookUrl,
        },
      }),
    });

    if (response.ok) {
      // Gérer la réussite de la requête
      await checkWebhookConnection();
      setUpdateTrigger((prev) => prev + 1); // Change la valeur pour déclencher useEffect
      handleCloseModalWebhook();

      mixpanel.track("Notification_Webhook", {
        user: context.userInfos.email,
        status: "Activate",
      });
    } else {
      // Gérer l'erreur
    }
  };

  useEffect(() => {
    checkWebhookConnection();
  }, [updateTrigger]);

  const checkDiscordConnection = async () => {
    if (!context.token) {
      return;
    }

    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/discord-connected`;
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${context.token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setIsDiscordConnected(data.discordConnected);

      if (data.discordConnected && data.discordUrl) {
        setDiscordUrl(data.discordUrl);
      } else {
        setDiscordUrl("");
      }
    } else {
      // Gérer l'erreur
    }
  };

  const checkSlackConnection = async () => {
    if (!context.token) {
      return;
    }

    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/slack-connected`;
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${context.token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setIsSlackConnected(data.slackConnected);

      if (data.slackConnected && data.slackUrl) {
        setSlackUrl(data.slackUrl);
      } else {
        setSlackUrl("");
      }
    } else {
      // Gérer l'erreur
    }
  };

  const checkWebhookConnection = async () => {
    if (!context.token) {
      return;
    }

    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/webhook-connected`;
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${context.token}`,
      },
    });

    if (response.ok) {
      const data = await response.json();
      setIsWebhookConnected(data.webhookConnected);

      if (data.webhookConnected && data.webhookUrl) {
        setWebhookUrl(data.webhookUrl);
      } else {
        setWebhookUrl("");
      }
    } else {
      // Gérer l'erreur
    }
  };

  const handleSendEmail = async () => {
    if (!isValidEmail(emailInput)) {
      toast.error(t("email_is_not_valid"));
      return;
    }
    // Construire l'URL de l'API
    const apiUrl =
      process.env.REACT_APP_BASEURL + `/api/teams/send-invitation/`;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: emailInput,
          invitedBy: context.userInfos.id,
          teamId: teamId,
        }),
      });

      const data = await response.json();

      if (data.success) {
        toast.success(t("invite_leave_for_your_collegue"));
        setEmailInput(""); // Réinitialiser l'input
        setIsModalVisible(false);
        await loadInvitations(); // Rafraîchir la liste des invitations après envoi réussi

        mixpanel.track("Invited_teammates", {
          user: context.userInfos.email,
          invited_user: emailInput,
        });
      } else {
        toast.error(t("error_sending_invite"));
      }
    } catch (error) {
      toast.error(t("An_error_has_occured"));
    }
  };

  const handleOk = () => {
    console.log("ok");
  };

  function handleTeamClick() {
    setShowDropdown(!showDropdown);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const deleteKeyword = (keyObj) => {
    setKeywordToDelete(keyObj.keywordName);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    const apiUrl = `${process.env.REACT_APP_BASEURL}/api/teams/deletekeyword/`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      },
      body: JSON.stringify({ keyword: keywordToDelete, teamId }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          return Promise.reject(data);
        }
        return response.json();
      })
      .then((data) => {
        toast.success(data.msg);

        // Supprimez le mot-clé du tableau basé sur `keywordName` et mettez à jour le contexte
        const updatedKeywords = context.teamDetails.keyword.filter(
          (kw) => kw.keywordName !== keywordToDelete
        );
        context.setTeamDetails((prev) => ({
          ...prev,
          keyword: updatedKeywords,
        }));

        if (localStorage.getItem("selectedKeyword") === keywordToDelete) {
          localStorage.removeItem("selectedKeyword");
          // Choisissez un nouveau mot-clé à sélectionner si possible
          if (updatedKeywords.length > 0) {
            localStorage.setItem(
              "selectedKeyword",
              updatedKeywords[0].keywordName
            );
            handleKeywordChange(updatedKeywords[0].keywordName);
          }
        }

        setShowDeleteModal(false);
        setKeywordToDelete(null);
      })
      .catch((error) => {
        toast.error(error.msg || t("error_deleting_keyword"));
      });
  };

  useEffect(() => {}, [currentKeywordCount]);

  const handleEmailNotificationChange = (memberId, newValue) => {
    const apiUrl =
      process.env.REACT_APP_BASEURL + `/api/teams/update-email-notification/`;

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      },
      body: JSON.stringify({ memberId, emailNotification: newValue }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          return await Promise.reject(data);
        }
        return response.json();
      })
      .then((data) => {
        toast.success(data.msg);

        setEmailNotifications((prevState) => ({
          ...prevState,
          [memberId]: newValue,
        }));

        mixpanel.track("Notification_Email", {
          user: context.userInfos.email,
          status: newValue,
        });
      })
      .catch((error) => {
        toast.error(error.msg || t("error_updating_email_notif"));
      });
  };

  async function subscribeUserToPush(memberId) {
    const registration = await navigator.serviceWorker.ready;

    try {
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.REACT_APP_VAPID_PUBLIC_KEY
        ),
      });
      // Envoyez cette souscription au serveur
      await sendSubscriptionToServer(memberId, subscription);
    } catch (error) {
      console.error(t("error_during_subscription"), error);
    }
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  function sendSubscriptionToServer(memberId, subscription) {
    fetch(
      process.env.REACT_APP_BASEURL +
        `/api/teams/save-subscription/${memberId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
        body: JSON.stringify(subscription),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to save subscription on server.");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Saved subscription on server:", data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleNavClick = () => {
    if (window.innerWidth <= 768) {
      toggleNav();
    }
  };

  const canAddMember = () => {
    if (!isFreeTrialOver) {
      return currentTeamMembersCount < MAX_PRO_MEMBERS;
    }

    switch (currentPlan) {
      case "Pro":
        return currentTeamMembersCount < MAX_PRO_MEMBERS;
      case "Ultimate":
        return currentTeamMembersCount < MAX_ULTIMATE_MEMBERS;
      default:
        return false;
    }
  };

  const canAddKeyword = () => {
    const currentPlan = teamDetails?.plan?.[0]?.productName || "Free";

    if (!isFreeTrialOver) {
      // Appliquez la limite correcte en fonction du plan pendant la période d'essai
      if (currentPlan === "Ultimate") {
        return currentKeywordCount < MAX_KEYWORDS_ULTIMATE;
      } else {
        return currentKeywordCount < MAX_KEYWORDS_PRO;
      }
    }

    // Logique pour les utilisateurs ayant un plan payant
    switch (currentPlan) {
      case "Pro":
        return currentKeywordCount < MAX_KEYWORDS_PRO;
      case "Ultimate":
        return currentKeywordCount < MAX_KEYWORDS_ULTIMATE;
      default:
        return false;
    }
  };

  const handleBrowserNotificationChange = async (memberId, newValue) => {
    try {
      // Si l'utilisateur active les notifications
      if (newValue) {
        const permission = await Notification.requestPermission();

        if (permission !== "granted") {
          toast.error(t("you_need_to_allow_notifications"));
          return;
        }
        // Après avoir obtenu la permission, inscrivez l'utilisateur aux notifications push.
        await subscribeUserToPush(memberId);
      }

      const response = await fetch(
        process.env.REACT_APP_BASEURL +
          `/api/teams/update-browser-notification/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({ memberId, browserNotification: newValue }),
        }
      );

      const data = await response.json();
      if (!response.ok) throw new Error(data.msg);

      toast.success(data.msg);

      // Mettez à jour l'état des notifications pour le membre spécifique
      setBrowserNotifications((prevState) => ({
        ...prevState,
        [memberId]: newValue,
      }));

      mixpanel.track("Notification_Browser", {
        user: context.userInfos.email,
        status: newValue,
      });
    } catch (error) {
      toast.error(error.message || t("error_updating_browser_notification"));
    }
  };

  const handleUserMenuClickOutside = (event) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setIsUserMenuOpen(false);
    }
  };

  useEffect(() => {
    const checkLinkedInCookie = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASEURL}/api/user/check-linkedin-cookie`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${
                context.token || localStorage.getItem("Token")
              }`,
            },
          }
        );

        const responseData = await response.json();
        if (response.ok && !responseData.linkedinCookieActivate) {
          setLinkedinCookieInvalid(true);
        }
      } catch (error) {
        console.error("Error checking LinkedIn cookie:", error);
      }
    };

    checkLinkedInCookie();
  }, [context.token]);

  const handleRegenerateApiKey = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/teams/${context.teamId}/generate-api-key`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(
          "Erreur lors de la requête de régénération de la clé API"
        );
      }

      const data = await response.json();
      setNewApiKey(data.apiKey);
      setShowRegenerateModal(true);

      mixpanel.track("Generate_API_Key", {
        user: context.userInfos.email,
        status: "Generate",
      });
    } catch (error) {
      console.error("Erreur lors de la génération de la clé API", error);
    }
  };

  useEffect(() => {
    // Ajouter l'écouteur lors du montage
    document.addEventListener("mousedown", handleUserMenuClickOutside);

    // Supprimer l'écouteur lors du démontage
    return () => {
      document.removeEventListener("mousedown", handleUserMenuClickOutside);
    };
  }, []);

  const addTeamMember = () => {
    setIsModalVisible(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setKeywordToDelete(null);
  };

  const AddKeywordNew = () => {
    if (!context.teamDetails.premium && isFreeTrialOver) {
      toast.error(t("You_must_be_premium_to_add_a_keyword"));
      return;
    }

    if (!canAddKeyword() && !isEditing) {
      toast.error(t("youve_reached_the_max_number_keywords"));
      return;
    }

    if (!currentKeyword.trim()) {
      toast.error(t("Please_enter_a_valid_keyword"));
      return;
    }

    const cleanedKeyword = currentKeyword.replace(/^["']|["']$/g, "").trim();
    if (!cleanedKeyword) {
      toast.error(t("Please_enter_a_valid_keyword"));
      return;
    }

    // Validation pour vérifier si l'intent est rempli
    if (!keywordIntent.trim()) {
      toast.error(t("Please_enter_a_valid_intent"));
      return;
    }

    const body = isEditing
      ? JSON.stringify({
          keyword: cleanedKeyword,
          description: keywordDescription,
          intent: keywordIntent || "",
          website: keywordURL,
          urls: exclusionUrls,
          oldKeyword: oldKeyword,
          teamId: context.teamDetails._id,
        })
      : JSON.stringify({
          newKeyword: cleanedKeyword,
          description: keywordDescription,
          intent: keywordIntent || "",
          website: keywordURL,
          urls: exclusionUrls,
          teamId: context.teamDetails._id,
        });

    const url = isEditing
      ? `${process.env.REACT_APP_BASEURL}/api/teams/updatekeyword/`
      : `${process.env.REACT_APP_BASEURL}/api/teams/addnewkeyword/`;

    const method = isEditing ? "PUT" : "POST";

    fetch(`${url}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      },
      body: body,
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          throw new Error(data.msg || t("error"));
        }
        return response.json();
      })
      .then(async (data) => {
        toast.success(data.msg);
        if (isEditing) {
          const updatedKeywords = teamDetails.keyword.map((k) =>
            k.keywordName === oldKeyword ? newKeyword : k
          );
          context.setTeamDetails((prev) => ({
            ...prev,
            keyword: updatedKeywords,
          }));
        } else {
          const updatedKeywords = [...teamDetails.keyword, newKeyword];
          context.setTeamDetails((prev) => ({
            ...prev,
            keyword: updatedKeywords,
          }));
        }

        mixpanel.track("Add_Keyword", {
          user: context.userInfos.email,
          keyword: cleanedKeyword,
          intent: keywordIntent,
          website: keywordURL,
        });

        closeModal();
        setCurrentKeyword("");
        setKeywordDescription("");
        setkeywordIntent("");
        setKeywordURL("");
        setExclusionUrls([]);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  function saveAllDetails() {
    handleTeamNameUpdate();
    handleProfileUpdate();
    handleSavePlatforms();
    closeModalSettings();
  }

  function handleTeamNameUpdate() {
    const currentMember = teamDetails.members.find(
      (member) => member.userId._id === context.userInfos.id
    );

    if (currentMember && currentMember.role === "admin") {
      updateTeamName(teamDetails._id, teamName);
    } else {
      toast.error(t("you_dont_have_right_to_change_the_team_name"));
    }
  }

  async function updateTeamName(teamId, newName) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/teams/update-team-name`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({ teamId, newName }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.msg);
      }

      setTeamName(newName);

      // toast.success(data.msg);
    } catch (error) {
      // toast.error(error.message);
    }
  }

  useEffect(() => {
    const fetchTeamBrowserNotifications = async () => {
      if (!context.teamId || !context.token) return;
      try {
        const response = await fetch(
          process.env.REACT_APP_BASEURL +
            `/api/teams/get-team-browser-notifications/${context.teamId}`,
          {
            headers: { Authorization: `Bearer ${context.token}` },
          }
        );

        if (!response.ok)
          throw new Error(
            t("error_retreiving_notifications_from_team_browser")
          );

        const data = await response.json();
        setBrowserNotifications(data.browserNotifications);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTeamBrowserNotifications();
  }, [context.token, context.teamId]);

  // SET CLASS AFTER SCROLL
  const [isShrink, setIsShrink] = useState("");
  const currentScroll = useScrollPosition();

  useEffect(() => {
    // NAV OPEN CLASS
    if (isOpenNav) {
      setOpenNavClass("navOpen");
    } else {
      setOpenNavClass("");
    }
    // SHRINK NAV
    if (currentScroll > 50) {
      setIsShrink("shrink");
    } else {
      setIsShrink("");
    }
  }, [isOpenNav, currentScroll]);

  useEffect(() => {
    setUser(context.userInfos);
  }, [context.userInfos]);

  // window.addEventListener("scroll", function () {
  //   var navbar = document.getElementById("globalHeader");
  //   if (navbar) {
  //     // Ajout de la vérification ici
  //     if (window.pageYOffset > 0) {
  //       navbar.classList.add("shrink");
  //     } else {
  //       navbar.classList.remove("shrink");
  //     }
  //   }
  // });

  useEffect(() => {
    // SHRINK NAV
    // Assurez-vous que vous avez accès à 'pathname' ici, soit en le passant comme une prop,
    // soit en l'obtenant via 'useLocation' si vous utilisez react-router
    const path = window.location.pathname;
    if (currentScroll > 50 && path !== "/login" && path !== "/register") {
      setIsShrink("shrink");
    } else {
      setIsShrink("");
    }

    if (path == "/" || path == "") {
      setIsShrink("shrink");
    }
  }, [currentScroll]);

  const handleClickOut = (event) => {
    if (
      keywordMenuRef.current &&
      !keywordMenuRef.current.contains(event.target)
    ) {
      setShowKeywordMenu(false);
    }
  };

  useEffect(() => {
    // Ajouter l'écouteur lors du montage
    document.addEventListener("mousedown", handleClickOut);

    // Supprimer l'écouteur lors du démontage
    return () => {
      document.removeEventListener("mousedown", handleClickOut);
    };
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Vérification de la taille
    if (file.size > 3 * 1024 * 1024) {
      // 3 Mo en octets
      alert(t("the_file_is_too_big_image_upload"));
      return;
    }

    const reader = new FileReader();

    reader.onloadend = async () => {
      // La chaîne base64 sera dans reader.result
      setBase64Image(reader.result);

      // Appel de la fonction handleSubmit pour envoyer l'image au serveur
      await handleSubmit(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleRoleChange = (memberId, newRole) => {
    // console.log("ici faut changer le role");
  };

  const handleDeleteInvitation = (invitationId) => {
    // Appelez votre API pour supprimer l'invitation
    fetch(
      `${process.env.REACT_APP_BASEURL}/api/teams/invitations/${invitationId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${context.token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to delete the invitation.");
        }
        return response.json();
      })
      .then(() => {
        // Affichez un message de succès
        // Et mettez à jour l'état pour refléter le changement dans l'UI
        setInvitations((currentInvitations) =>
          currentInvitations.filter(
            (invitation) => invitation._id !== invitationId
          )
        );
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSubmit = async (imageToSend) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + `/api/user/update-profile-image/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify({
            userId: context.userInfos.id,
            image: imageToSend,
          }),
        }
      );

      if (!response.ok) {
        // Essayez d'obtenir le message d'erreur du serveur
        let errorMsg;
        try {
          const data = await response.json();
          errorMsg = data.msg || t("error_updating_image");
        } catch (jsonError) {
          errorMsg = t("error_updating_image");
        }

        throw new Error(errorMsg);
      }

      const data = await response.json();

      if (data.success) {
        context.setUserInfos({ ...context.userInfos, image: imageToSend });
      }
    } catch (error) {
      toast.error(t("The_picture_is_too_large"));
    }
  };

  async function handleProfileUpdate() {
    try {
      // Vérifiez si les valeurs ont changé
      if (
        firstName === context.userInfos.firstname &&
        lastName === context.userInfos.surname &&
        email === context.userInfos.email &&
        !password // Si le mot de passe est vide, cela signifie qu'il n'a pas été modifié
      ) {
        return;
      }

      const updatedData = {
        email: email,
        firstname: firstName,
        surname: lastName,
      };

      if (password !== confirmPassword) {
        toast.error(t("passwords_do_no_match"));
        return;
      }

      // Si le mot de passe n'est pas vide, ajoutez-le à updatedData
      if (password) {
        updatedData.password = password;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/user/update-profile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${context.token}`,
          },
          body: JSON.stringify(updatedData),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.msg);
      }

      setPassword("");
      setConfirmPassword("");

      // toast.success("Profile updated successfully!");
    } catch (error) {
      toast.error(error.message);
    }
  }

  const UserLogout = () => {
    return (
      <div className="userDropdown" ref={userMenuRef}>
        <div className="userHeader">
          <img
            src={
              context.userInfos && context.userInfos.image
                ? context.userInfos.image
                : "default-user.svg"
            }
            alt="User Image"
            className="userImage"
          />
          <div className="userInfo">
            <p className="userName">
              {context.userInfos ? context.userInfos.firstname : ""}
            </p>
            <p className="userEmail">{context.userInfos.email}</p>
          </div>
        </div>

        <hr className="dottedLine" id="MenuSettingsItemdottedLine" />

        <div className="settingHeader" onClick={disconnectUser}>
          <div className="settingItem">
            <i className="settingIcon fa-solid fa-power-off"></i>
            <span className="settingText">{t("Log_out")}</span>
          </div>
        </div>
      </div>
    );
  };

  const UserDropdown = () => {
    return (
      <div className="userDropdown" ref={userMenuRef}>
        <div className="userHeader">
          <img
            src={
              context.userInfos && context.userInfos.image
                ? context.userInfos.image
                : "default-user.svg"
            }
            alt="User Image"
            className="userImage"
          />
          <div className="userInfo">
            <p className="userName">
              {context.userInfos ? context.userInfos.firstname : ""}
            </p>
            <p className="userEmail">{context.userInfos.email}</p>
          </div>
        </div>

        <hr className="dottedLine" id="MenuSettingsItemdottedLine" />

        <div className="settingHeader" id="MenuSettingsItem">
          <div
            className="settingItem"
            onClick={() => {
              setActivePage("General");

              mixpanel.track("Load_page", {
                user: context.userInfos.email,
                page: "General Settings",
              });

              toggleSettingsModal();
            }}
          >
            <i className="settingIcon fa-solid fa-cog"></i>
            <span className="settingText">{t("Settings")}</span>
          </div>
          {/* <div className="settingItem">
            <i className="settingIcon fa-solid fa-moon"></i>
            <span className="settingText">Dark Mode</span>
            <Switch
              checked={isDarkMode}
              className="darkmodeSwitch"
              onChange={(checked) => handleDarkModeToggle(checked)}
            />
          </div> */}
        </div>

        {/* <hr className="dottedLine" id="MenuSettingsItemdottedLine" /> */}

        <div className="settingHeader" id="MenuTeamItem">
          {/* <div className="settingItem">
            <i className="settingIcon fa-solid fa-gauge"></i>
            <a href="/dashboard">
              <span className="settingText">Dashboard</span>
            </a>
          </div> */}
          {/* <div className="settingItem">
            <i className="settingIcon fa-solid fa-message"></i>
            <a href="/feed">
              <span className="settingText">Feed</span>
            </a>
          </div> */}
          <div
            className="settingItem"
            onClick={() => {
              setActivePage("Team");

              mixpanel.track("Load_page", {
                user: context.userInfos.email,
                page: "Team Settings",
              });

              toggleSettingsModal();
            }}
          >
            <i className="settingIcon fa-solid fa-users"></i>
            <span className="settingText">{t("Teams")}</span>
          </div>
          {/* <div
            className="settingItem"
            onClick={() => {
              setActivePage("Subscriptions");

              mixpanel.track("Load_page", {
                user: context.userInfos.email,
                page: "Subscriptions Settings",
              });

              toggleSettingsModal();
            }}
          >
            <i className="settingIcon fa-solid fa-star"></i>
            <span className="settingText">{t("Subscription")}</span>
          </div> */}
          {/* <div
            className="settingItem"
            onClick={() => {
              setActivePage("Referrals");
              toggleSettingsModal();
            }}
          >
            <i className="settingIcon fa-solid fa-heart-circle-plus"></i>
            <span className="settingText">Referrals</span>
          </div> */}
        </div>

        <hr className="dottedLine" />

        <div className="settingHeader" onClick={disconnectUser}>
          <div className="settingItem">
            <i className="settingIcon fa-solid fa-power-off"></i>
            <span className="settingText">{t("Log_out")}</span>
          </div>
        </div>
      </div>
    );
  };

  const handleTeamChange = (teamId) => {
    const team = teams.find((t) => t._id === teamId);
    setSelectedTeam(team);
    setTeamDetails(team);
    localStorage.setItem("selectedTeamId", team._id);
    setTeamId(team._id);
  };

  useEffect(() => {
    // Fonction pour récupérer la valeur de la notification par e-mail pour un membre spécifique
    const fetchEmailNotification = async () => {
      if (!context.userInfos) {
        return; // Si context.userInfos est null ou undefined, sortez de la fonction
      }
      try {
        const response = await fetch(
          process.env.REACT_APP_BASEURL +
            `/api/teams/get-email-notification/${context.userInfos.id}`,
          {
            headers: {
              Authorization: `Bearer ${context.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(t("error_retreiving_email_notif"));
        }

        const data = await response.json();

        setIsEmailActivate(data.emailNotification);
      } catch (error) {
        console.error(error);
      }
    };

    fetchEmailNotification();
  }, [context.token, context.userInfos]);

  useEffect(() => {
    const fetchTeamEmailNotifications = async () => {
      if (!context.teamId || !context.token) return;

      try {
        const response = await fetch(
          process.env.REACT_APP_BASEURL +
            `/api/teams/get-team-email-notifications/${context.teamId}`,
          {
            headers: { Authorization: `Bearer ${context.token}` },
          }
        );

        if (!response.ok)
          throw new Error(t("error_retreiving_team_email_notif"));

        const data = await response.json();
        setEmailNotifications(data.emailNotifications);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTeamEmailNotifications();
  }, [context.token, context.teamId]);

  useEffect(() => {
    async function fetchTeams() {
      setLoading(true);
      const userId = context.userInfos?.id ?? null;
      const storedTeamId = localStorage.getItem("selectedTeamId");

      // Exécuter ce bloc uniquement si storedTeamId est défini
      if (userId && storedTeamId) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASEURL}/api/teams/user/${userId}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${context.token}`,
              },
              method: "GET",
            }
          );

          const data = await response.json();

          if (data.success) {
            setTeams(data.teams);

            const team = data.teams.find((t) => t._id === storedTeamId);
            if (team) {
              setSelectedTeam(team);
              setKeywords(team.keyword);

              // Vérifiez si l'équipe est premium
              if (team.premium || !isFreeTrialOver) {
                setIsPremiumTeam(true);
              }

              if (team.premium) {
                setPremiumTeamCondition(true);
              }

              // Si aucun mot-clé n'est sélectionné, définir le premier mot-clé comme valeur par défaut
              if (!context.keyword && team.keyword.length > 0) {
                context.setKeyword(team.keyword[0].keywordName);
              }
            }
          } else {
            console.error("Error fetching teams:", data.message);
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      }
      setLoading(false);
    }

    fetchTeams();
  }, [context, refresh]);

  <div className="teamData" onClick={handleTeamClick}>
    <div className="teamImage">
      {/* <img src={teamDetails ? teamDetails.image : "/testpic.jpg"} /> */}
    </div>
    <div className="teamInfo">
      <h4>{teamDetails ? teamDetails.name : t("select_a_team")}</h4>
      <p>
        {teamDetails ? `${teamDetails.members.length} ${t("members")}` : ""}
      </p>
    </div>
    <div className="teamArrow">
      <span>
        <i className="fa-solid fa-caret-down"></i>
      </span>
    </div>
  </div>;

  {
    showDropdown && (
      <div className="customDropdown">
        {teams.map((team) => (
          <div
            key={team._id}
            onClick={() => handleTeamChange(team._id)}
            className="dropdownItem"
          >
            {team.name}
          </div>
        ))}
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  if ((pathname === "/" || pathname === "/login") && context.isAuthenticate) {
    navigate("/leads");
  }

  if (pathname.startsWith("/freetools/")) {
    // Ne pas rendre la topbar pour ces routes
    return null;
  }

  if (pathname.startsWith("/extension/")) {
    // Ne pas rendre la topbar pour ces routes
    return null;
  }

  if (context.isFromExtension) {
    return null;
  }

  if (user && user.id !== "") {
    return (
      <>
        <nav id="globalHeaderLog" className={isShrink + " " + openNavClass}>
          <div className="mobileMenu" onClick={toggleNav}>
            <span></span>
          </div>
          <div className="navWrap wrapXL">
            <Link to="/dashboard" className="logo">
              <img
                width="110"
                className="logoimg"
                src="/logo.svg"
                alt="Buska Logo"
              />
            </Link>
            {/* <span>&nbsp;</span> */}
            <div className="flex">
              {/* <div className="searchBar">
              <span>
                <i
                  style={{ marginRight: "10px" }}
                  className="fas fa-search"
                ></i>{" "}
                Search mentions, socials, tasks and more
              </span>
            </div> */}
              <ul>
                <a
                  target="_blank"
                  href="https://app.lemcal.com/@buska/demo-with-our-team"
                >
                  <span className="bookDemo">
                    <i className="fa-solid fa-laptop mr10"></i>
                    {t("book_a_demo_with_our_team")}
                  </span>
                </a>

                {!premiumTeamCondition &&
                  (isFreeTrialOver ? (
                    <span
                      className="freeTrial"
                      onClick={handleClickOpenPlan}
                      style={{ cursor: "pointer" }}
                    >
                      {t("Your_free_trial_is_finished")}
                    </span>
                  ) : daysRemainingForTrial > 0 ? (
                    <span
                      className="freeTrial"
                      onClick={handleClickOpenPlan}
                      style={{ cursor: "pointer" }}
                    >
                      {t("Your_free_trial_ends_in")} {daysRemainingForTrial}{" "}
                      {t("days")}
                    </span>
                  ) : null)}

                <li>
                  <div className="keywordFilterNav" onClick={toggleKeywordMenu}>
                    {t("Keyword")}:{" "}
                    <span className="keyword">
                      {context.keyword ? context.keyword : t("unknow")}
                    </span>
                    <i className="small fa-solid fa-chevron-down"></i>
                  </div>

                  {showKeywordMenu && (
                    <>
                      <div className="keywordMenu" ref={keywordMenuRef}>
                        {keywords.map((keyObj, index) => (
                          <div
                            key={index}
                            onClick={() =>
                              handleKeywordChange(keyObj.keywordName)
                            }
                          >
                            <div>
                              <i
                                className="fa-solid fa-tag"
                                style={{
                                  color: "#9E00FF",
                                  marginRight: "10px",
                                }}
                              ></i>
                              {/* <span className="keywordList">{key}</span> */}
                              <span
                                style={{
                                  fontWeight:
                                    keyObj.keywordName === context.keyword
                                      ? "bold"
                                      : "normal",
                                }}
                              >
                                {keyObj.keywordName}
                              </span>
                              <i
                                className="editTrash fa-solid fa-edit"
                                style={{ color: "#B0B0B0" }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  editKeyword(keyObj);
                                }}
                              ></i>
                              <i
                                className="keywordTrash fa-solid fa-trash"
                                style={{
                                  color: "#B0B0B0",
                                  marginRight: "10px",
                                }}
                                onClick={() => deleteKeyword(keyObj)}
                              ></i>
                            </div>
                          </div>
                        ))}
                        <hr className="dottedLineWithoutSpace" />
                        {!isPremiumTeam ? (
                          // Affichage pour une team non premium
                          <div
                            onClick={() => setShowPlansModal(true)}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <i
                                className="fa-solid fa-plus"
                                style={{
                                  color: "#9E00FF",
                                  marginRight: "10px",
                                }}
                              ></i>
                              {t("add_a_new_keyword")}
                            </div>
                            <span className="premiumFeature">
                              {t("PREMIUM")}
                            </span>
                          </div>
                        ) : (
                          // Affichage pour une team premium
                          <div
                            onClick={() => toggleKeywordModal()}
                            style={{
                              display: canAddKeyword() ? "flex" : "none", // cache le bouton si l'utilisateur a atteint la limite
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <i
                                className="fa-solid fa-plus"
                                style={{
                                  color: "#9E00FF",
                                  marginRight: "10px",
                                }}
                              ></i>
                              {t("add_a_new_keyword")}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </li>
                <Modal
                  width={"60%"}
                  className="modalSettings"
                  id="modalSettings"
                  open={isSettingsModalOpen}
                  onCancel={toggleSettingsModal}
                  // footer={null}
                  closable={false}
                  maskClosable={false}
                  footer={[
                    <Button
                      key="back"
                      className="cancelButton"
                      onClick={() => closeModalSettings(false)}
                    >
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="submit"
                      className="ctaBase"
                      onClick={() => saveAllDetails()}
                    >
                      <i className="fa-solid fa-floppy-disk"></i>{" "}
                      <span className="ml10">{t("Save")}</span>
                    </Button>,
                  ]}
                >
                  <div className="modalContent">
                    <div className="leftColumn">
                      <h4>{t("Settings")}</h4>
                      <hr className="dottedLine" />

                      <div
                        className="settingItem"
                        onClick={() => setActivePage("General")}
                      >
                        <i
                          className={`settingIcon ${
                            activePage === "General" ? "settingIconActive" : ""
                          } fa-solid fa-gear `}
                        ></i>
                        <span
                          className={`settingText ${
                            activePage === "General" ? "settingTextActive" : ""
                          }`}
                        >
                          {t("General")}
                        </span>
                      </div>

                      <div
                        className="settingItem"
                        onClick={() => setActivePage("Team")}
                      >
                        <i
                          className={`settingIcon ${
                            activePage === "Team" ? "settingIconActive" : ""
                          } fa-solid fa-bell `}
                        ></i>
                        <span
                          className={`settingText ${
                            activePage === "Team" ? "settingTextActive" : ""
                          }`}
                        >
                          {t("Notifications_Team")}
                        </span>
                      </div>

                      {/* <div
                      className="settingItem"
                      onClick={() => setActivePage("Subscriptions")}
                    >
                      <i
                        className={`settingIcon ${
                          activePage === "Subscriptions"
                            ? "settingIconActive"
                            : ""
                        } fa-solid fa-star `}
                      ></i>
                      <span
                        className={`settingText ${
                          activePage === "Subscriptions"
                            ? "settingTextActive"
                            : ""
                        }`}
                      >
                        {t("Subscription")}
                      </span>
                    </div> */}

                      <div
                        className="settingItem"
                        onClick={() => setActivePage("API")}
                      >
                        <i
                          className={`settingIcon ${
                            activePage === "API" ? "settingIconActive" : ""
                          } fa-solid fa-gear `}
                        ></i>
                        <span
                          className={`settingText ${
                            activePage === "API" ? "settingTextActive" : ""
                          }`}
                        >
                          {t("API")}
                        </span>
                      </div>

                      <div
                        className="settingItem"
                        onClick={() => setActivePage("Networks")}
                      >
                        <i
                          className={`settingIcon ${
                            activePage === "Networks" ? "settingIconActive" : ""
                          } fa-solid fa-wifi `}
                        ></i>
                        <span
                          className={`settingText ${
                            activePage === "Networks" ? "settingTextActive" : ""
                          }`}
                        >
                          {t("Networks")}
                        </span>
                      </div>

                      <div
                        className="settingItem"
                        // onClick={() => setActivePage("Referrals")}
                      >
                        <i
                          className={`settingIcon ${
                            activePage === "Referrals"
                              ? "settingIconActive"
                              : ""
                          } fa-solid fa-heart-circle-plus `}
                        ></i>
                        <span
                          className={`settingText ${
                            activePage === "Referrals"
                              ? "settingTextActive"
                              : ""
                          }`}
                        >
                          {t("Referrals")}{" "}
                          <span className="newPastille">{t("Soon")}</span>
                        </span>
                      </div>

                      <hr className="dottedLine" />

                      {/* <div
                      className="settingItem"
                      onClick={() => setActivePage("Tokens")}
                    >
                      <img
                        className={`settingIcon ${
                          activePage === "Tokens" ? "settingIconActive" : ""
                        }`}
                        style={{ maxWidth: "15px", marginLeft: "10px" }}
                        src={
                          activePage === "Tokens"
                            ? "/b_token_active.svg"
                            : "/b_token.svg"
                        }
                      />
                      <span
                        className={`settingText ${
                          activePage === "Tokens" ? "settingTextActive" : ""
                        }`}
                      >
                        Tokens
                      </span>
                    </div> */}
                      <div className="imgSettingModal">
                        <img src="/Puzzle.svg" />
                      </div>
                    </div>
                    <div
                      className={
                        activePage === "Subscriptions"
                          ? "rightContentWithout"
                          : activePage === "API"
                          ? "rightContentAPI"
                          : "rightContent"
                      }
                    >
                      {activePage === "General" && (
                        <>
                          <div className="profileSection">
                            <img
                              src={
                                context.userInfos && context.userInfos.image
                                  ? context.userInfos.image
                                  : "default-user.svg"
                              }
                              alt="User Profile"
                              className="userImage"
                            />
                            <input
                              type="file"
                              id="fileUpload"
                              className="fileUpload"
                              onChange={handleImageUpload}
                            />
                            <label
                              htmlFor="fileUpload"
                              className="ctaPrimaryBlack"
                            >
                              <i className="fa-solid fa-upload"></i>{" "}
                              {t("upload_an_image")}
                            </label>
                          </div>
                          <div className="nameSection">
                            <label htmlFor="firstName">{t("First_Name")}</label>
                            <input
                              type="text"
                              className="input"
                              id="firstName"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>

                          <div className="nameSection">
                            <label htmlFor="lastName">{t("Last_Name")}</label>
                            <input
                              type="text"
                              className="input"
                              id="lastName"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>

                          <div className="nameSection">
                            <label htmlFor="email">{t("Email")}</label>
                            <input
                              type="email"
                              className="input disabled"
                              id="email"
                              value={email}
                              readOnly
                            />
                          </div>

                          <div className="nameSection">
                            <label htmlFor="lastName">{t("Password")}</label>
                            <input
                              type="password"
                              className="input"
                              id="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>

                          <div className="nameSection">
                            <label htmlFor="confirmPassword">
                              {t("Confirm_Password")}
                            </label>
                            <input
                              type="password"
                              className="input"
                              id="confirmPassword"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </div>

                          <div className="nameSection">
                            <label htmlFor="languageSelect">
                              {t("Select_Language")}
                            </label>
                            <select
                              id="languageSelect"
                              value={i18n.language}
                              onChange={(e) => changeLanguage(e.target.value)}
                              className="input"
                            >
                              <option value="en-EN">English</option>
                              <option value="fr-FR">Français</option>
                              <option value="es-ES">Español</option>
                              <option value="pt-PT">Português</option>
                            </select>
                          </div>

                          <br />

                          {/* <div className="actionButtons">
                          <button
                            className="cancelButton"
                            onClick={() => closeModalSettings()}
                          >
                            Cancel
                          </button>
                          <button className="ctaBase">
                            <i className="fa-solid fa-floppy-disk"></i> Save
                          </button>
                        </div> */}
                        </>
                      )}
                      {activePage === "Team" && (
                        <>
                          <div className="membersList">
                            <span className="title">
                              {t("Your_team_and_notifications")}
                            </span>
                            <br />
                            <br />
                            <ul className="borderBottom">
                              <li className="tableHeader">
                                <div className="userColumn">{t("User")}</div>
                                <div className="emailColumn">{t("Email")}</div>
                                <div className="browserColumn">
                                  {t("Browser")}
                                </div>
                                <div className="roleColumn">{t("Role")}</div>
                              </li>
                              {teamDetails.members &&
                                teamDetails.members.map((member) => (
                                  <li key={member._id}>
                                    <img
                                      src={
                                        member.userId && member.userId.image
                                          ? member.userId.image
                                          : "default-user.svg"
                                      }
                                      alt="User Profile"
                                      className="imgMemberTeam"
                                    />

                                    <div className="nameAndEmailContainer">
                                      <span className="nameMemberTeam">
                                        {member.userId.firstname}{" "}
                                        {member.userId.surname}
                                      </span>
                                    </div>

                                    <div className="emailColumn">
                                      <Switch
                                        className="emailSwitch"
                                        checked={
                                          emailNotifications[
                                            member.userId._id
                                          ] || false
                                        }
                                        disabled={
                                          context.userInfos.id !==
                                          member.userId._id
                                        }
                                        onChange={(newValue) => {
                                          handleEmailNotificationChange(
                                            member.userId._id,
                                            newValue
                                          );
                                        }}
                                      />
                                    </div>

                                    <div className="browserColumn">
                                      <Switch
                                        className="browserSwitch"
                                        checked={
                                          browserNotifications[
                                            member.userId._id
                                          ] || false
                                        }
                                        disabled={
                                          context.userInfos.id !==
                                          member.userId._id
                                        }
                                        onChange={(newValue) => {
                                          handleBrowserNotificationChange(
                                            member.userId._id,
                                            newValue
                                          );
                                        }}
                                      />
                                    </div>
                                    <select
                                      className="ctaActionSettings textTest disabled"
                                      value={member.role}
                                      onChange={(e) => {
                                        // Mettez à jour le rôle du membre ici
                                        // Par exemple : handleRoleChange(member._id, e.target.value);
                                      }}
                                    >
                                      {roles.map((role) => (
                                        <option key={role} value={role}>
                                          {role}
                                        </option>
                                      ))}
                                    </select>
                                    <span></span>
                                    <i className="fa-solid fa-angle-down"></i>

                                    <span className="iconTrash">
                                      <i className="fa-solid fa-trash disabled"></i>
                                    </span>
                                  </li>
                                ))}
                              {invitations &&
                                invitations
                                  .filter(
                                    (invitation) => invitation.status === "sent"
                                  )
                                  .map((invitation) => (
                                    <li key={invitation._id}>
                                      <img
                                        src="default-user.svg"
                                        alt="User Invitation"
                                        className="imgMemberTeam"
                                      />
                                      <div className="nameAndEmailContainer">
                                        <span className="nameMemberTeam">
                                          {invitation.email}
                                        </span>
                                      </div>

                                      <div className="emailColumn">
                                        <span>
                                          {t("Status")}: {t("Sent")}
                                        </span>
                                      </div>

                                      <span className="iconTrash">
                                        <i
                                          className="fa-solid fa-trash"
                                          onClick={() =>
                                            handleDeleteInvitation(
                                              invitation._id
                                            )
                                          }
                                        ></i>
                                      </span>
                                    </li>
                                  ))}
                              <button
                                className="ctaSecondary"
                                onClick={() => {
                                  if (isPremiumTeam) {
                                    if (canAddMember()) {
                                      addTeamMember();
                                    } else {
                                      toast.error(
                                        "You have reached the invitation limit for your plan"
                                      );
                                    }
                                  } else {
                                    setShowPlansModal(true);
                                  }
                                }}
                              >
                                <i className="fa-solid fa-user-plus"></i>{" "}
                                {t("Add_new_team_member")}
                              </button>

                              <br />
                              <br />
                            </ul>
                          </div>
                          <Modal
                            title={t("Invite_a_buska_friend_in_your_team")}
                            open={isModalVisible}
                            width={"20%"}
                            className="inviteFriendModal"
                            onCancel={() => setIsModalVisible(false)}
                            footer={[
                              <Button
                                key="back"
                                type="ctaSecondary"
                                onClick={() => setIsModalVisible(false)}
                              >
                                {t("Cancel")}
                              </Button>,
                              <Button
                                key="submit"
                                type="ctaPrimaryWhite"
                                className="ctaPrimaryWhite"
                                onClick={handleSendEmail}
                              >
                                {t("Send_invitation")}
                              </Button>,
                            ]}
                          >
                            <label htmlFor="Friendemail">
                              {t("Friends_email")}
                            </label>
                            <input
                              placeholder="ex: test@buska.io"
                              value={emailInput}
                              onChange={(e) => setEmailInput(e.target.value)}
                            />
                            <img src="/Mail.svg" />
                          </Modal>

                          <ul className="borderBottom">
                            <div className="nameSection">
                              <label htmlFor="firstName">
                                {t("Team_Name")}
                              </label>
                              <br />
                              <input
                                type="text"
                                className="input"
                                id="teamname"
                                value={teamName}
                                onChange={(e) => setTeamName(e.target.value)}
                              />
                            </div>
                            <div className="nameSection">
                              <label htmlFor="currency">{t("Currency")}</label>
                              <br />
                              <select
                                className="input selectCurrency"
                                id="currency"
                                value={currency}
                                onChange={handleCurrencyChange}
                              >
                                <option value="EUR">EUR (€)</option>
                                <option value="USD">USD ($)</option>
                              </select>
                            </div>
                            <br />
                          </ul>

                          <div className="nameSection borderBottom">
                            <label htmlFor="firstName">
                              {t("Integrations")}
                            </label>
                            <br />
                            <ul className="columnsContainer">
                              <li>
                                <img
                                  className="whatsapp"
                                  src="/slack.svg"
                                  alt="Slack Icon"
                                />
                              </li>

                              <li>
                                <div className="nameAndEmailContainer">
                                  <span className="nameSocial">
                                    {currentPlan !== "Ultimate" && (
                                      <span
                                        className="premiumFeature"
                                        style={{ display: "inline" }}
                                      >
                                        {t("PREMIUM")}
                                      </span>
                                    )}{" "}
                                    Slack{" "}
                                    {isSlackConnected && (
                                      <span className="urlConnectedNotif">
                                        {slackUrl}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </li>

                              <li>
                                <button
                                  className="ctaPrimaryBlack"
                                  onClick={() => {
                                    if (currentPlan !== "Ultimate") {
                                      setShowPlansModal(true);
                                    } else if (isSlackConnected) {
                                      handleOpenModalSlackDisconnect();
                                    } else {
                                      handleOpenModalSlack();
                                    }
                                  }}
                                >
                                  <i className="fa-solid fa-link"></i>{" "}
                                  {currentPlan === "Ultimate" &&
                                  isSlackConnected
                                    ? t("Disconnect")
                                    : t("Connect")}
                                </button>
                              </li>
                            </ul>
                            <ul className="columnsContainer">
                              <li>
                                <img
                                  className="whatsapp"
                                  src="/webhook.svg"
                                  alt="Webhook Icon"
                                />
                              </li>

                              <li>
                                <div className="nameAndEmailContainer">
                                  <span className="nameSocial">
                                    {currentPlan !== "Ultimate" && (
                                      <span
                                        className="premiumFeature"
                                        style={{ display: "inline" }}
                                      >
                                        {t("PREMIUM")}
                                      </span>
                                    )}{" "}
                                    Webhook
                                    {isWebhookConnected && (
                                      <span className="urlConnectedNotif">
                                        {webhookUrl}
                                      </span>
                                    )}
                                    {isWebhookConnected && (
                                      <span
                                        className="testConnexion"
                                        onClick={() =>
                                          testWebhookConnection(webhookUrl)
                                        }
                                      >
                                        {t("Test_the_connection")}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </li>

                              <li>
                                <button
                                  className="ctaPrimaryBlack"
                                  onClick={() => {
                                    if (currentPlan !== "Ultimate") {
                                      setShowPlansModal(true);
                                    } else if (isWebhookConnected) {
                                      handleDisconnectWebhook();
                                    } else {
                                      handleOpenModalWebhook();
                                    }
                                  }}
                                >
                                  <i className="fa-solid fa-link"></i>{" "}
                                  {currentPlan === "Ultimate" &&
                                  isWebhookConnected
                                    ? t("Disconnect")
                                    : t("Connect")}
                                </button>
                              </li>
                            </ul>
                            <ul className="columnsContainer">
                              <li>
                                <img
                                  className="whatsapp"
                                  src="/Discord.png"
                                  alt="Discord Icon"
                                />
                              </li>

                              <li>
                                <div className="nameAndEmailContainer">
                                  <span className="nameSocial">
                                    {currentPlan !== "Ultimate" && (
                                      <span
                                        className="premiumFeature"
                                        style={{ display: "inline" }}
                                        onClick={() => setShowPlansModal(true)}
                                      >
                                        {t("PREMIUM")}
                                      </span>
                                    )}{" "}
                                    Discord{" "}
                                    {isDiscordConnected && (
                                      <span className="urlConnectedNotif">
                                        {discordUrl}
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </li>

                              <li>
                                <button
                                  className="ctaPrimaryBlack"
                                  onClick={() => {
                                    if (currentPlan !== "Ultimate") {
                                      setShowPlansModal(true);
                                    } else if (isDiscordConnected) {
                                      handleOpenModalDiscordDisconnect();
                                    } else {
                                      handleOpenModalDiscord();
                                    }
                                  }}
                                >
                                  <i className="fa-solid fa-link"></i>{" "}
                                  {currentPlan === "Ultimate" &&
                                  isDiscordConnected
                                    ? t("Disconnect")
                                    : t("Connect")}
                                </button>
                              </li>
                            </ul>
                          </div>

                          <br />

                          {/* <div className="actionButtons">
                          <button
                            className="cancelButton"
                            onClick={() => closeModalSettings()}
                          >
                            Cancel
                          </button>
                          <button className="ctaBase">
                            <i className="fa-solid fa-floppy-disk"></i> Save
                          </button>
                        </div> */}
                        </>
                      )}
                      {activePage === "Subscriptions" && (
                        <>
                          <Plans />
                        </>
                      )}

                      {activePage === "API" && (
                        <>
                          <div className="membersList">
                            <span className="title">
                              {t("API_configuration")}
                            </span>
                            <br />
                            <span
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              {t("Discover_the_api_documentation")}{" "}
                              <a
                                target="_blank"
                                href="https://developers.buska.io/"
                              >
                                {t("click_here_actually")}
                              </a>
                              .
                            </span>
                            <br />
                            <span>
                              {t(
                                "As_an_owner_of_this_project_you_can_view_and_manage_all_API_keys"
                              )}
                            </span>
                          </div>

                          {/* {currentPlan === "Ultimate" && ( */}
                          <div className="noPremium">
                            <div className="membersList">
                              <div className="nameSection">
                                <label className="firstName">
                                  {t("Your_remaining_API_credits")} :{" "}
                                  <span className="">
                                    {numberCreditApi === 0
                                      ? t("no_credits")
                                      : numberCreditApi}
                                  </span>
                                  <br />
                                  <a onClick={handleLinkClick}>
                                    <span className="linkBuy primaryColor">
                                      {t("Buy_more_api_credits")}
                                    </span>
                                  </a>
                                </label>
                              </div>
                            </div>

                            <div className="membersList">
                              <div className="nameSection">
                                <label className="firstName">
                                  {t("API_request_usage_by_month")}
                                </label>
                              </div>
                              <APIGraph />
                            </div>
                          </div>
                          {/* )} */}
                          <br />
                          <div className="nameSection">
                            <label className="firstName">
                              {t("new_api_key")}
                            </label>
                            <span>
                              {t("Click_on_the_button_generate_api_key")}
                            </span>
                            {/* {currentPlan !== "Ultimate" && (
                            <span
                              className="premiumFeature"
                              style={{ display: "inline" }}
                            >
                              {t("PREMIUM")}
                            </span>
                          )}{" "} */}
                            <ul className="columnsContainer">
                              <li>
                                <img
                                  className="whatsapp"
                                  src="/api.svg"
                                  alt="API Icon"
                                />
                              </li>

                              <li>
                                <div className="nameAndEmailContainer">
                                  <input
                                    type="password"
                                    className="apiKeyInput"
                                    value={
                                      "this is a random api key false just for the example"
                                    }
                                    readOnly
                                  />
                                </div>
                              </li>

                              <li>
                                <button
                                  className="ctaPrimaryBlack"
                                  onClick={() => {
                                    handleRegenerateApiKey();
                                  }}
                                >
                                  <i className="fa-solid fa-rotate"></i>{" "}
                                  {t("generate_again")}
                                </button>
                              </li>
                            </ul>
                          </div>

                          {showPlansApi && (
                            <PlansApi
                              isVisible={showPlansApi}
                              onClose={handleClosePlansApi}
                            />
                          )}
                          {/* <br />
                        <div className="nameSection">
                          Ici mettre un composant d'achat de crédit API
                          <PlansApi />
                        </div> */}
                        </>
                      )}

                      {activePage === "Networks" && (
                        <div>
                          <h3>{t("Select_Networks")}</h3>
                          <br />
                          <div className="platforms-container">
                            {platforms.map((platform) => (
                              // <Tooltip
                              //   key={platform.name}
                              //   title={
                              //     platform.name === "LinkedIn"
                              //       ? t(
                              //           "linkedin_has_been_disabled_for_the_moment"
                              //         )
                              //       : ""
                              //   }
                              // >
                              <div
                                key={platform.name}
                                className={`platform-card ${
                                  selectedPlatforms.includes(platform.name)
                                    ? "selected"
                                    : ""
                                } ${
                                  platform.isPremium &&
                                  currentPlan !== "Ultimate"
                                    ? "premium"
                                    : ""
                                }`}
                                onClick={() => handlePlatformToggle(platform)}
                              >
                                {platform.isPremium &&
                                  currentPlan !== "Ultimate" && (
                                    <div className="premium-tag">Premium</div>
                                  )}
                                {platform.customIcon ? (
                                  <img
                                    src={platform.customIcon}
                                    alt={platform.name}
                                    className="platform-icon"
                                  />
                                ) : (
                                  <i
                                    className={`fa ${platform.icon} platform-icon`}
                                  ></i>
                                )}
                                <span>{platform.name}</span>
                              </div>
                              // </Tooltip>
                            ))}
                          </div>

                          {showLinkedInMessage && (
                            <div className="linkedin-message">
                              <p>
                                {t(
                                  "to_monitor_linkedin_you_must_download_the_chrome_ext"
                                )}
                                <a
                                  href="https://chromewebstore.google.com/detail/buska-linkedin/fbmgcejhoneccecnplfllgkfgheoengm"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span className="downloadExtension">
                                    {t("download_here")}
                                  </span>
                                </a>
                              </p>
                              <br />
                              <i className="fa-solid fa-circle-info"></i>{" "}
                              <a
                                className="nolink"
                                target="_blank"
                                href="https://www.buska.io/ressources/how-to-monitor-linkedin-with-buska-a-step-by-step-guide"
                              >
                                {t(
                                  "click_here_if_you_need_assistance_to_connect_the_extension"
                                )}
                              </a>{" "}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={t("new_api_key")}
                  open={showRegenerateModal}
                  width={400}
                  className="apiKeyModal"
                  onCancel={() => setShowRegenerateModal(false)}
                  footer={[
                    <Button
                      key="close"
                      onClick={() => setShowRegenerateModal(false)}
                    >
                      {t("close")}
                    </Button>,
                  ]}
                  closable={false}
                  maskClosable={false}
                >
                  {t("remember_to_save_this_api_ket")} : <br />
                  <br />
                  <span className="bold">{newApiKey}</span>
                </Modal>
                <Plans
                  isVisible={showPlansModal}
                  onClose={() => setShowPlansModal(false)}
                />
                <Modal
                  title="Delete Keyword"
                  open={showDeleteModal}
                  width={400}
                  className="deleteKeywordModal"
                  onCancel={handleCloseDeleteModal}
                  footer={[
                    <Button key="cancel" onClick={handleCloseDeleteModal}>
                      {t("Cancel")}
                    </Button>,
                    <Button
                      key="confirm"
                      type="primary"
                      onClick={handleConfirmDelete}
                    >
                      {t("Delete")}
                    </Button>,
                  ]}
                  closable={false}
                  maskClosable={false}
                >
                  {t("are_you_sure_to_delete_keyword")}{" "}
                  <span className="bold">{keywordToDelete}</span>?
                </Modal>
                <Modal
                  width={400}
                  className="modalNewKeyword"
                  id="modalNewKeyword"
                  open={isKeywordModalOpen}
                  onCancel={closeModal}
                  footer={null}
                  closable={false}
                  maskClosable={false}
                >
                  <div>
                    <h2>
                      {isEditing ? t("edit_keyword") : t("add_a_new_keyword")}
                    </h2>
                    {/* <p>{t("Type_your_keyword")}</p> */}

                    <span className="subTitleModal">
                      {t("your_keyword")}* :
                    </span>
                    <input
                      placeholder="Apple"
                      className="inputKeyword"
                      value={currentKeyword}
                      onChange={(e) => setCurrentKeyword(e.target.value)}
                    />

                    {/* <span className="subTitleModal">
                    {t("description_keyword_context")}:
                  </span>
                  <textarea
                    rows="3"
                    placeholder={t("Description_keyword")}
                    className="inputKeyword"
                    value={keywordDescription}
                    onChange={(e) => setKeywordDescription(e.target.value)}
                  /> */}

                    <span className="subTitleModal">
                      {t("Keyword_intent")}* :
                    </span>
                    <textarea
                      rows="3"
                      placeholder={t("Description_intent")}
                      className="inputKeyword"
                      value={keywordIntent}
                      onChange={(e) => setkeywordIntent(e.target.value)}
                    />
                    <span className="subTitleModal">
                      {t("URL_describe_keyword")}:
                    </span>
                    <input
                      placeholder="www.apple.com"
                      className="inputKeyword"
                      value={keywordURL}
                      onChange={(e) => setKeywordURL(e.target.value)}
                    />

                    <span className="subTitleModal">{t("Exclude_url")}:</span>
                    <Form
                      name="dynamic_form_exclusion_urls"
                      onFinish={onFinish}
                      autoComplete="off"
                      key={currentKeyword}
                    >
                      <Form.List
                        name="exclusionUrls"
                        initialValue={exclusionUrls}
                        rules={[
                          {
                            validator: async (_, urls) => {
                              if (!urls || urls.length < 1) {
                                return Promise.reject(
                                  new Error("At least one URL must be entered")
                                );
                              }
                            },
                          },
                        ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map((field, index) => (
                              <Form.Item
                                key={field.key}
                                style={{ marginBottom: 0 }}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={["onChange", "onBlur"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "please_input_an_URL_or_delete"
                                      ),
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Input
                                    placeholder={t("Enter_URL_to_exclude")}
                                    style={{
                                      width: "93%",
                                      height: "38px",
                                      display: "inline",
                                    }}
                                    className="inputKeyword"
                                    value={field.value || ""}
                                    onChange={(e) => {
                                      const newUrls = [...exclusionUrls];
                                      newUrls[index] = e.target.value;
                                      setExclusionUrls(newUrls);
                                    }}
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    const newUrls = exclusionUrls.filter(
                                      (_, idx) => idx !== index
                                    );
                                    setExclusionUrls(newUrls);
                                    remove(field.name);
                                  }}
                                  style={{ margin: "0 5px" }}
                                />
                              </Form.Item>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => {
                                  setExclusionUrls([...exclusionUrls, ""]);
                                  add();
                                }}
                                icon={<PlusOutlined />}
                                style={{ width: "100%" }}
                              >
                                {t("add_url")}
                              </Button>
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Form>

                    {/* <img className="img" src="/Rainy.svg" /> */}
                    <div
                      className="actionButtons"
                      style={{ marginTop: "10px" }}
                    >
                      <button className="cancelButton" onClick={closeModal}>
                        {t("Cancel")}
                      </button>
                      <button onClick={AddKeywordNew} className="ctaBase">
                        {isEditing ? t("Save") : t("Add")}
                      </button>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={t("buskino_scans_the_web_for_you")}
                  open={isModalKeywordConfirmation}
                  width={"40%"}
                  className="keywordConfirmationModal"
                  onCancel={() => setIsModalKeywordConfirmation(false)}
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  footer={[
                    <Button
                      key="submit"
                      type="ctaPrimaryWhite"
                      className="ctaPrimaryWhite"
                      onClick={() => setIsModalKeywordConfirmation(false)}
                    >
                      {t("Ok")}
                    </Button>,
                  ]}
                >
                  <div className="modalContentContainer">
                    <div className="modalImageContainer">
                      <img src="Time.svg" alt="Buskino scans the web" />
                    </div>
                    <div className="modalTextContainer">
                      <span>{t("wow_great_keyword")}</span>
                    </div>
                  </div>
                </Modal>
                <Modal
                  title={t("webhook_to_discord")}
                  className="modalDiscord"
                  open={modalOpenDiscord}
                  onOk={() => handleSubmitUrlNotifDiscord()} // Appel de la fonction sans paramètres
                  onCancel={handleCloseModalDiscord}
                  okText={t("Save")}
                  cancelText={t("Cancel")}
                >
                  <input
                    placeholder={t("enter_url_webhook_discord_url")}
                    value={discordUrl}
                    className="input"
                    style={{ width: "100%" }}
                    onChange={(e) => setDiscordUrl(e.target.value)}
                  />
                  <p className="guideDetails" style={{ marginTop: "10px" }}>
                    <i className="fa-solid fa-circle-question"></i>{" "}
                    {t("Discover_how_connect_your_webhook_to_discord")}:{" "}
                    <a
                      target="_blank"
                      href="https://www.buska.io/ressources/connect-buska-to-discord"
                    >
                      {t("click_here")}.
                    </a>
                  </p>
                </Modal>
                <Modal
                  title={t("webhook_to_slack")}
                  className="modalDiscord"
                  open={modalOpenSlack}
                  onOk={() => handleSubmitUrlNotifSlack()} // Appel de la fonction sans paramètres
                  onCancel={handleCloseModalSlack}
                  okText={t("Save")}
                  cancelText={t("Cancel")}
                >
                  <input
                    placeholder={t("enter_url_webhook_slack_url")}
                    value={slackUrl}
                    className="input"
                    style={{ width: "100%" }}
                    onChange={(e) => setSlackUrl(e.target.value)}
                  />

                  <p className="guideDetails" style={{ marginTop: "10px" }}>
                    <i className="fa-solid fa-circle-question"></i>{" "}
                    {t("Discover_how_connect_your_webhook_to_slack")}:{" "}
                    <a
                      target="_blank"
                      href="https://www.buska.io/ressources/connect-buska-to-slack"
                    >
                      {t("click_here")}.
                    </a>
                  </p>
                </Modal>
                <Modal
                  title={t("buska_to_webhook")}
                  className="modalDiscord"
                  open={modalOpenWebhook}
                  onOk={() => handleSubmitUrlNotifWebhook()} // Appel de la fonction sans paramètres
                  onCancel={handleCloseModalWebhook}
                  okText={t("Save")}
                  cancelText={t("Cancel")}
                >
                  <input
                    placeholder={t("enter_url_webhook_url")}
                    value={webhookUrl}
                    className="input"
                    style={{ width: "100%" }}
                    onChange={(e) => setWebhookUrl(e.target.value)}
                  />
                  <p className="guideDetails" style={{ marginTop: "10px" }}>
                    <i className="fa-solid fa-circle-question"></i>{" "}
                    {t("Discover_how_connect_your_webhook")}:{" "}
                    <a
                      target="_blank"
                      href="https://www.buska.io/ressources/connect-buska-to-webhook"
                    >
                      {t("click_here")}.
                    </a>
                  </p>
                </Modal>
                <li>
                  {/* <div className="teamSelect">
                  <div className="teamImageColumn">
                    <img src="/B.svg" alt="Team" />
                  </div>

                  <div className="teamInfoColumn">
                    <h4>{teamDetails ? teamDetails.name : "Select a team"}</h4>
                    <p>
                      {teamDetails
                        ? `${teamDetails.members.length} member(s)`
                        : ""}
                    </p>
                  </div>

                  <div className="teamGearColumn" onClick={toggleSettingsModal}>
                    <i className="fas fa-cog"></i>
                  </div>
                </div> */}

                  {/* {showDropdown && (
                    <div className="customDropdown">
                      {teams.map((team) => (
                        <div
                          key={team._id}
                          onClick={() => handleTeamChange(team._id)}
                          className="dropdownItem"
                        >
                          {team.name}
                        </div>
                      ))}
                    </div>
                  )} */}
                </li>
                <li
                  className="menuUserOpen"
                  onClick={(e) => {
                    setIsUserMenuOpen((prev) => !prev); // Change l'état basé sur l'état précédent
                  }}
                >
                  <div className="menuTopHeaderUser">
                    <img
                      src={
                        context.userInfos && context.userInfos.image
                          ? context.userInfos.image
                          : "default-user.svg"
                      }
                      alt="User Image"
                      className="userImage"
                    />
                  </div>
                  {isUserMenuOpen && context.userInfos && <UserDropdown />}
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {linkedinCookieInvalid && (
          <>
            <div className="ErrorLinkedinAlert">
              <span>
                <i className="fa-solid fa-triangle-exclamation"></i>
                {t("your_linkedin_session_has_expired")}
              </span>
              {/* <Alert
                message="Your LinkedIn session has expired. Please reconnect."
                type="error"
                showIcon
                banner
              /> */}
            </div>
          </>
        )}
      </>
    );
  }

  // NOT LOGGED
  // return (
  //   <>
  //     <nav id="globalHeader" className={isShrink + "  " + openNavClass}>
  //       {!(pathname === "/register" || pathname === "/login") && (
  //         <div className="mobileMenu" onClick={toggleNav}>
  //           <span></span>
  //         </div>
  //       )}

  //       {pathname !== "/login" &&
  //         pathname !== "/register" &&
  //         pathname !== "/legals" &&
  //         pathname !== "/" &&
  //         pathname !== "/about" &&
  //         pathname !== "/affiliate" &&
  //         pathname !== "/privacy" && (
  //           <div className="navWrap wrapXL">
  //             {openNavClass ? (
  //               <Link to="/" className="logo">
  //                 <img
  //                   width="110"
  //                   className="logoimg"
  //                   src="/buska_white.svg"
  //                   alt="Buska Logo"
  //                 />
  //               </Link>
  //             ) : (
  //               <Link to="/" className="logo">
  //                 <img
  //                   width="110"
  //                   className="logoimg"
  //                   src="/buska-logo-union.png"
  //                   alt="Buska Logo"
  //                 />
  //               </Link>
  //             )}
  //             <ul>
  //               <>
  //                 <li className="">
  //                   <ScrollLink
  //                     to="features"
  //                     smooth={true}
  //                     duration={500}
  //                     onClick={handleNavClick}
  //                   >
  //                     {t("Features")}
  //                   </ScrollLink>
  //                 </li>

  //                 <li>
  //                   <ScrollLink
  //                     to="monthlyPricing"
  //                     smooth={true}
  //                     duration={500}
  //                     onClick={handleNavClick}
  //                   >
  //                     {t("Pricing")}
  //                   </ScrollLink>
  //                 </li>

  //                 <li>
  //                   <ScrollLink
  //                     to="FAQ"
  //                     smooth={true}
  //                     duration={500}
  //                     onClick={handleNavClick}
  //                   >
  //                     {t("FAQ")}
  //                   </ScrollLink>
  //                 </li>

  //                 <li className="mobile-only">
  //                   <br />
  //                 </li>

  //                 <li className="mobile-only">
  //                   <a href="/login">{t("login")}</a>
  //                 </li>

  //                 <li className="mobile-only">
  //                   <a href="/register">{t("register_now")}</a>
  //                 </li>
  //               </>

  //               <li className="social">
  //                 <a
  //                   target="_blank"
  //                   href="https://www.linkedin.com/company/buskapp"
  //                 >
  //                   <i className="black fa-brands fa-linkedin"></i>
  //                 </a>
  //               </li>
  //               <li className="social">
  //                 <a target="_blank" href="https://twitter.com/Buska_io">
  //                   <i className="black fa-brands fa-twitter"></i>
  //                 </a>
  //               </li>

  //               <li className="social">
  //                 <a target="_blank" href="https://www.tiktok.com/@buska.io">
  //                   <i className="black fa-brands fa-tiktok"></i>
  //                 </a>
  //               </li>
  //             </ul>

  //             <ul>
  //               <>
  //                 <li>
  //                   <a
  //                     href="https://www.producthunt.com/posts/buska?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-buska"
  //                     target="_blank"
  //                     className="phVign"
  //                   >
  //                     <img
  //                       src="/ph.svg"
  //                       alt="Buska - Know&#0032;instantly&#0032;what&#0032;they&#0032;say&#0032;about&#0032;your&#0032;brand&#0032;online | Product Hunt"
  //                       width="230"
  //                       height="47"
  //                     />
  //                   </a>
  //                 </li>

  //                 <li className="ctaPrimaryHome">
  //                   <a href="/login">{t("login")}</a>
  //                 </li>

  //                 <button className="ctaFullHome">
  //                   <a
  //                     className=""
  //                     style={{
  //                       color: "white",
  //                       paddingLeft: "20px",
  //                       paddingRight: "20px",
  //                     }}
  //                     href="/register"
  //                   >
  //                     {t("join_buska_for_free")}
  //                   </a>
  //                 </button>
  //               </>
  //             </ul>
  //           </div>
  //         )}
  //     </nav>
  //   </>
  // );
};

export default NavBar;
