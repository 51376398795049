import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import { AuthContext } from "../context/AuthContext.js";

import moment from "moment";
import "moment/locale/fr";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import { Modal, Skeleton } from "antd";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";
import MenuFeed from "../components/MenuFeed.js";
import Plans from "../components/Plans.js";
import LeadsTable from "../components/LeadsTable.js";

import mixpanel from "mixpanel-browser";

// STYLES
import "./Leads.css";

function Leads() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  // const [selectedSources, setSelectedSources] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [isClockView, setIsClockView] = useState(false);
  const [mentionFilter, setMentionFilter] = useState("all");

  const {
    teamId,
    keyword,
    token,
    teamDetails,
    userInfos,
    selectedSources,
    setSelectedSources,
  } = useContext(AuthContext);

  if (loading) {
    return <Loader />;
  }

  if (context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="">
            <>
              <main id="UserDashboard">
                <div className="">
                  <MenuFeed
                    allPosts={allPosts}
                    selectedSources={selectedSources}
                    setSelectedSources={setSelectedSources}
                    selectedLanguages={selectedLanguages}
                    setSelectedLanguages={setSelectedLanguages}
                    onMentionFilterChange={setMentionFilter}
                  />
                  <LeadsTable
                    selectedSources={selectedSources}
                    selectedLanguages={selectedLanguages}
                  />
                </div>
              </main>
            </>
          </div>
        </div>
      </>
    );
  }
}

export default Leads;
